.product-body {
	height: 100%;
	max-height: auto;
	padding: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.product-body-header {
	width: fit-content;
}
