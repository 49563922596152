.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	overflow-y: hidden;
}

.Modal {
	position: absolute;
	background-color: white;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	min-width: 75%;
	max-width: 85%;
	transform: translate(-50%, -50%);
	padding: 20px;
}

.modal-close-btn {
	position: relative;
	top: 0;
	right: 0;
	left: 90%;
}
.modal-close-btn i.far {
	font-size: 30px;
	color: #076d4d;
	cursor: pointer;
}
.modal-product-body {
	display: flex;
	flex-direction: row;
}
.modal-product-image {
	height: auto;
	margin: 0 auto;
	max-width: 100%;

	max-height: 500px;
}

.modal-product-title {
	display: flex;
	justify-items: left;
}
.modal-product-title-header {
	width: fit-content;
	max-width: 50%;
	font-size: 30px;
	margin: auto 30px auto 0;
}

.modal-list-title {
	text-transform: capitalize;
}

.modal-list {
	margin-bottom: 20px;
}

.modal-list li {
	margin-left: 25px;
}
.modal-list li:first-child {
	margin-left: 10px;
}
.modal-list li p {
	margin-bottom: 2px;
	text-transform: capitalize;
}

.modal-form-link {
	padding: 0;
	height: auto;
	width: fit-content;
	text-align: center;
	display: flex;
	flex-direction: row;
	border: none;
	margin: auto 0;
	background-color: white;
}
.modal-form-link p {
	width: fit-content;
	font-weight: bold;
	font-size: 11px;
	height: auto;
	color: #076d4d;
	margin: auto;
	padding: 1px 20px 1px 0;
	background-color: rgb(185, 185, 185);
}
.modal-form-link i {
	background-color: #076d4d;
	color: white;
	padding: 10px 20px;
	font-size: 14px;
	margin: auto 15px auto 0;
}

.product-col-1,
.product-col-2 {
	width: 50%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.product-col-2 {
	padding: 0 30px 0 30px;
}

.modal-form-link-1024 {
	display: none;
}

@media only screen and (max-width: 1024px) {
	.modal-product-title {
		display: flex;
		flex-wrap: wrap;
	}
	.modal-product-title-header {
		max-width: 80%;

		margin-bottom: 30px;
	}
	.modal-form-link-1024 {
		padding: 0;
		height: auto;
		width: fit-content;
		text-align: center;
		display: flex;
		flex-direction: row;
		border: none;
		margin: auto auto auto 30px;
		background-color: white;
	}
	.modal-form-link-1024 p {
		width: fit-content;
		font-weight: bold;
		font-size: 11px;
		height: auto;
		color: #076d4d;
		margin: auto;
		padding: 1px 20px 1px 0;
		background-color: rgb(185, 185, 185);
	}
	.modal-form-link-1024 i {
		background-color: #076d4d;
		color: white;
		padding: 10px 20px;
		font-size: 14px;
		margin: auto 15px auto 0;
	}
	.modal-form-link {
		display: none;
	}
}
