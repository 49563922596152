.nav-cart-li {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.cart-remove-icon {
    margin: auto 20px auto 0;
    font-size: 20px;
    cursor: pointer;
}

.cart-model-title {
	width: 100%;
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
	text-transform: capitalize;
	padding: 10px 0 10px 20px;
	text-align: start;
	color: black;
	border: none;
    letter-spacing: 0.03em;
    margin: auto 0;
}
.cart-empty {
    text-transform: none;
}


@media only screen and (max-width: 1440px) {

}

@media only screen and (max-width: 1024px) {
.cart-empty{
    font-size: 14px;
}
}
@media only screen and (max-width: 768px) {
.cart-empty{
    font-size: 14px;
}
.cart-model-title{
    font-size: 14px;
}
}