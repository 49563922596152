.product-image {
	max-height: 400px;
	height: auto;
	width: auto;
	max-width: 400px;
	cursor: pointer;
}
.product:hover {
	transform: scale(1.1);
}
.product-title {
	font-weight: bold;
	cursor: pointer;
}
.product {
	width: 30%;
	max-width: 400px;

	background-color: inherit;
	margin: auto 4px 4px 4px;
	padding: 10px 0 0 0;
}
.product-col-1 {
	width: 25%;
}
.product-col-2 {
	width: 75%;
}

@media only screen and (max-width: 1440px) {
	.product-image {
		max-height: 400px;
		height: auto;
		width: auto;
		max-width: 300px;
	}
}
@media only screen and (max-width: 1024px) {
	.product-image {
		max-width: 230px;
	}
}
@media only screen and (max-width: 768px) {
	.product-image {
		max-width: 230px;
	}
	.product {
		width: 45%;
	}
}
@media only screen and (max-width: 460px) {
	.product-image {
		max-width: 230px;
	}
	.product {
		width: 100%;
	}
}
