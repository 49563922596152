.cart-icon {
	width: 100%;
	
    background-color: white;
}

.cart-icon p {
   margin: auto 6% auto auto;
    width: fit-content;
	color: white;
	background-color: #3a9578;
    border: 2px solid #076d4d;
    padding: 6px 10px;
    border-radius: 10px;
}
@media only screen and (min-width: 769px) {
	.cart-icon {
		display: none;
    }
   
}
