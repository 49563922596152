.wrapper {
	display: none;
}

.mobile-dropdown {
	height: auto;
	width: fit-content;
	z-index: 100;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
	
}

.mobile-disappear {
	display: none;
}

.mobile-dropdown li {
	width: 110%;
	height: auto;

	text-align: start;
}

.wrapper h3 {
	width: fit-content;
	padding: 0 5px;
	margin: 0 auto 10px auto;
}
.mobile-list-button {
	width: fit-content;
	text-align: center;
	cursor: pointer;
	align-self: center;
}
.mobile-list-button p {
	width: fit-content;
	margin: 0 auto;
	text-align: center;

	font-weight: 500;
}
.mobile-list-button i {
	font-size: 20px;
	text-align: center;
}

.mobile-form-btn {
	margin-right: 0;
	display: flex;
	flex-direction: row;

	background-color: rgb(185, 185, 185);

	border: none;
	padding: 0;
	cursor: pointer;
}

.mobile-form-btn p {
	color: black;
	font-size: 16px;
	text-transform: uppercase;
	background-color: rgb(185, 185, 185);
	margin: auto auto auto 20px;
	font-weight: 600;
	border: none;
}
.mobile-form-btn i {
	margin: auto 0;
	padding: 13px;
	color: white;
	font-size: 20px;
	background-color: #076d4d;
}

.mobile-nav-form-section {
	margin-top: 0;
}

@media only screen and (max-width: 768px) {
	.wrapper {
		display: block;
	}
}
