.remove-buttons {
	outline: none;
	color: black;
	border: none;
	padding: 5px 15px;
	background-color: white;
	border: 1px solid black;
	font-weight: 600;
	border-left: 0;
}
.remove-buttons:first-of-type {
	background-color: #3a9578;
	outline: none;
	color: black;
	border-left: 0;
	border: 1px solid black;
}

@media only screen and (max-width: 768px) {
	.remove-section {
		width: fit-content;
		margin: auto;
		
	}
	.remove-section li {
		width: 100%;
	}
	.remove-section  p {
		font-size: 10px;
	}

}
