.App {
	text-align: center;
	display: flex;
	justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
	.App {
		flex-direction: column;
	}
}
