.form-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #3a9578;
	box-shadow: -2px 2px #3a9578;
	border-radius: 25px 0 25px 0;
	width: 80%;
	padding: 10px;
	margin: 40px auto auto auto;
	height: 100%;
}
.form-row-1,
.form-row-2 {
	width: 50%;
	text-align: center;
	margin: 0 auto;
}
.form-row-1 {
	margin: 10px 0 10px 10px;
}
.form-row-1 h3 {
	margin: 10px auto;
	border-bottom: 1px solid #3a9578;
	width: fit-content;
	padding-bottom: 5px;
}
.form-row-2 h3 {
	margin: 10px auto;
	border-bottom: 1px solid #3a9578;
	width: fit-content;
	padding-bottom: 5px;
}

.form-row-2 {
	margin: 10px 10px 10px 0;
}
.form-row-1 li {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: fit-content;
	margin: 5px auto;
}
.form-row-1 li p {
	font-size: 24px;
	margin: auto 10px;
	font-weight: 500;
	color: black;
}

.form-product-image {
	max-height: 50px;
}
.product-form {
	height: auto;
	padding: 0;
	width: 70%;
	margin: auto;

	display: flex;
	flex-direction: column;
}
.product-form input {
	padding: 5px 0 0 0;
	color: black;
	font-size: 18px;

	border: none;
	margin: 10px 0;
	border-bottom: 1px solid black;
}
.product-form input:first-of-type {
	margin-top: 30px;
}

.product-form input::placeholder {
	padding-left: 25px;
	text-transform: capitalize;
	color: black;
}
.product-form input:focus {
	border: none;
	box-shadow: none;
	outline: none;
	border-bottom: 1px solid black;
}

.product-form span.error-message {
	color: red;
	font-size: 18px;
}
.product-form-btn {
	margin: 30px auto 6px auto;
	display: flex;
	flex-direction: row;
	height: 50px;
	background-color: rgb(185, 185, 185);
	width: 70%;
	border: none;
	padding: 0;
	cursor: pointer;
}

.product-form-btn:active,
.product-form-btn:focus {
	transform: scale(1.03);
}
.product-form-btn i {
	margin: auto 0;
	padding: 16px;
	color: white;
	font-size: 18px;
	background-color: #076d4d;
}
.product-form-btn h3 {
	color: black;
	font-size: 18px;
	text-transform: uppercase;
	background-color: rgb(185, 185, 185);
	margin: auto;
	font-weight: 500;
	border: none;
}

.form-product-image {
	height: 400px;
	width: auto;
}

.form-success {
	margin: 20px;
}
.form-success .form-success-h3 {
	border-bottom: none;
}

.form-success i {
	color: #076d4d;
}

.reset-btn {
	text-decoration: underline;
	font-size: 10px;
	font-style: italic;
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.form-row-1 {
		width: 90%;
	}
	.form-row-2 {
		width: 80%;
	}
	.product-form {
		width: 100%;
	}
	.product-form-btn {
		width: 55%;
	}
	.product-form-btn h3 {
		font-size: 14px;
	}
	.form-row-1 li p {
		font-size: 14px;
	}

	.product-form-btn i {
		font-size: 16px;
	}
}
@media only screen and (max-width: 460px) {
	.form-container {
		border: none;
		box-shadow: none;
		border-radius: 25px 0 25px 0;
		width: 100%;
		padding: 0;
		margin: 40px auto auto auto;
	}
	.form-container h3 {
		font-size: 20px;
	}
	.product-form-btn h3 {
		font-size: 12px;
		padding: 0;
	}

	.product-form-btn i {
		font-size: 16px;
	}

	.form-row-1 li p {
		font-size: 14px;
	}
}
